const SLIDER_CONNER01 = [
  {
    filename: `img-oshiritantei-1.jpg`,
  },
];
const SLIDER_CONNER02 = [
  {
    filename: `img-oshiritantei-2.jpg`,
  },
];
const SLIDER_CONNER03 = [
  {
    filename: `img-oshiritantei-3.jpg`,
  },
];
const SLIDER_CONNER04 = [
  {
    filename: `img-oshiritantei-4.jpg`,
  },
];
const SLIDER_CONNER05 = [
  {
    filename: `img-oshiritantei-5.jpg`,
  },
];

const OSHIRI_TANTEI_CONNER = [
  {
    categories: 'team-lab', // カテゴリー名
    name: 'おしりたんていじむしょ', // エリア名
    detail: '<p>作品に登場する部屋を再現！おしりたんていと写真をとろう！</p>', // 説明
    img: 'img-oshiritantei-1.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_CONNER01,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'ププッとふわふわアスレチック', // エリア名
    detail: '<p>「おしりたんてい」と一緒にアスレチックを探検！ふわふわの壁をすり抜けたり、すべりだいで遊ぼう！巨大なおしりたんていの顔をくぐり抜けたらゴール！<br>※画像はイメージです。</p>', // 説明
    img: 'img-oshiritantei-2.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER02,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'おしりたんてい　かがみのめいろ', // エリア名
    detail: '<p>「おしりたんてい」がいっぱいいる！？鏡が沢山ある迷路を抜け出そう！<br>※画像はイメージです。</p>', // 説明
    img: 'img-oshiritantei-3.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER03,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'かいとうUのふうせんパニック', // エリア名
    description: '※別途料金500円(税込)', // クリック前表示説明文
    detail: '<p>巨大なスクリーンをタッチして3つのミッションを成功させ、かいとうUに奪われた『めがみのマフラー』を取り戻そう！<br>※有料アトラクションとなります。<br>※画像はイメージです。<br>※別途料金500円(税込)</p>', // 説明
    img: 'img-oshiritantei-4.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER04,
  },
  {
    categories: 'team-lab', // カテゴリー名
    name: 'おしりたんていグリーティング（土日祝のみ開催）', // エリア名
    description: '時間：10:15～／11:15～／12:15～／13:30～／14:30～／15:30～', // クリック前表示説明文
    detail: '<p>おしりたんていと一緒にダンスをおどっちゃおう！<br>※土日祝のみの開催となります。<br>時間：10:15～／11:15～／12:15～／13:30～／14:30～／15:30～</p>', // 説明
    img: 'img-oshiritantei-5.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_CONNER05,
  },
];
export default OSHIRI_TANTEI_CONNER;
