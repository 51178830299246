/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import { Link } from 'gatsby';
import React from 'react';
import Image from '../util/Image';
import SimpleSwiper from './Slider.fade';

export const List = (props) => (
  <div className="col-inner">
    <div className="col-image">
      <figure className="image">
        {props.imgSlider ? (
          <SimpleSwiper data={props.sliderImg} />
        ) : (
          <Image filename={props.img ? props.img : 'now-printing.jpg'} />
        )}
      </figure>
    </div>
    <div className={`col-content ${props.tac ? 'has-text-align-center' : ''}`}>
      <h3 className="ttl">
        {props.name}
      </h3>
      <p className="text">
        {props.description}
      </p>
    </div>
  </div>
);
const ContentsListUnko = (props) => (
  <>
    {props.noPointer ? (
      <div className="no-pointer">
        <List {...props} />
      </div>
    ) : (
      <div className="no-link">
        <List {...props} />
      </div>
    )}
  </>
);

export default ContentsListUnko;
