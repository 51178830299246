/**
 * Type: ページ
 * What: おしり探偵
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Button } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import OSHIRI_TANTEI_CONNER from '../config/Oshiritantei';
import ContentsListUnko from '../components/ContentsListUnko';
import PostListComponent from '../components/PostList/PostList.oshiritantei';
import TimerFunc from '../util/Timer';
import mainImg from '../images/oshiri-tantei/main-oshiri-tantei.jpg';

const pageTitle = 'おしりたんてい なぞときフェスティバル';
const pageDescription = '「おしりたんてい なぞときフェスティバル」をBOSS E・ZO FUKUOKAで開催！期間2022年4月23日（土）～5月8日（日）。今年のGWは、おしりたんていやブラウンと一緒に事件を推理しながら、謎解き迷路などにも挑戦しよう！';
const pageSlug = 'oshiri-tantei';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: mainImg,
    noAnimation: true,
  },
];

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;

  const [isModal, setIsModal] = useState(-1);

  // モーダル展開
  const onClickModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal(num);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };

  // モーダルコンテンツ
  const ModalContent = () => {
    const name = OSHIRI_TANTEI_CONNER[isModal] && OSHIRI_TANTEI_CONNER[isModal].name;
    const detail = OSHIRI_TANTEI_CONNER[isModal] && OSHIRI_TANTEI_CONNER[isModal].detail;
    const img = OSHIRI_TANTEI_CONNER[isModal] && OSHIRI_TANTEI_CONNER[isModal].img;
    const colorClass = OSHIRI_TANTEI_CONNER[isModal] && OSHIRI_TANTEI_CONNER[isModal].colorClass;
    const imgSlider = OSHIRI_TANTEI_CONNER[isModal] && OSHIRI_TANTEI_CONNER[isModal].imgSlider;
    const sliderImg = OSHIRI_TANTEI_CONNER[isModal] && OSHIRI_TANTEI_CONNER[isModal].sliderImg;
    return (
      <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
            {imgSlider ? (
              <SimpleSwiper data={sliderImg} />
            ) : (
              <Image filename={img || 'now-printing.jpg'} />
            )}
            </figure>
          </div>
          <div className="e-modal-content">
            <h3 className="ttl c-4">{name}</h3>
            <p
              dangerouslySetInnerHTML={{ __html: detail }}
            />
          </div>
        </div>
      </div>
    );
  };
  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap">
          <div className='mt-3 mb-3 sp-mb0 border-box-red sub-desc'>
            <p className="text is-3 is-3-sp tac fwb">本イベントは終了しました。<br />たくさんのご来場ありがとうございました</p>
          </div>
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner tac">
              {url.ticketReady === '1' ? (
                <div
                  className="btn-wrap mb-0"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Button
                    className="btn-buy"
                    href="https://ticket.e-zofukuoka.com/#/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    チケットを購入する
                  </Button>
                </div>
              ) : null}
              <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>新型コロナウイルス感染防止の対策について</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section sc-topics ">
        <div className="container mb0">
          <div className="inner mb0">
            <p className="has-text-align-center has-text-weight-bold mb-3 text is-3 is-3-sp">
              3月18日（金）より公開の映画最新作「映画おしりたんてい シリアーティ」を記念して、4月23日（土）～5月8日（日）までの期間、「おしりたんてい」のイベント「おしりたんてい なぞときフェスティバル」を<br />BOSS E・ZO FUKUOKAで開催！
            </p>
            <p className="has-text-align-center has-text-weight-bold mb-3 text is-3 is-3-sp">
              今年のGWは、おしりたんていやブラウンと一緒に事件を推理しながら、<br />アスレチックや迷路で遊ぼう！
            </p>
            <p className="has-text-align-center has-text-weight-bold text is-3 is-3-sp">
              チケットは好評発売中♪ぜひ詳細をチェックしてください！
            </p>
          </div>
        </div>
      </section>
      <PostListComponent />
      <section className="section sc-topics bs-topics">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline" id="area">
              <span>コーナー紹介</span>
            </h2>
            <div className="sc-columns now-event">
              {OSHIRI_TANTEI_CONNER.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col col-4 ${categories.colorClass}`}
                  key={categories.name}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                  onClick={(e) => onClickModal(e)}
                >
                  <ContentsListUnko {...categories} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="unkoGoods">
        <div className="inner-slim">
          <div className="table-base">
            <table>
              <tbody>
                <tr>
                  <th>入場料金</th>
                  <td>
                    <p>
                      一般（中学生以上）: 1,200円(税込)
                      <br />
                      こども（3歳～小学生）: 600円(税込)
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="title is-5">注意事項</h4>
          <article>
            <ul className="list-base">
              <li>当館ご利用の際のお怪我や損失・損害、その他トラブルなどにつきまして当館では一切責任を負いません。</li>
              <li><b>小学生以下のお子様のご入場には、保護者の同伴が必要です。</b></li>
              <li>ご入場されるお客さまはマスクの常時着用をお願いします。</li>
              <li>入場口にてお客様の検温を実施します。37.5度以上の発熱、強いだるさ（倦怠感）をはじめとする、体調不良のお客さまはご入場いただけません。</li>
              <li>営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。</li>
              <li>チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も当館は一切の責任を負いません。</li>
              <li>お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。</li>
              <li>イベントスペースでの喫煙、アメ・ガムを含む飲食はお断りしております。</li>
              <li><b>会場内にトイレ・休憩室はございません</b></li>
              <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
              <li>全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください</li>
              <li>本イベント会場内は、補助犬を含み動物を連れての入場はお断りしております。</li>
              <li>事前承諾のない商業目的の撮影や取材はお断りしております。</li>
            </ul>
            <h4 className="ttl-strip">持ち込み禁止物</h4>
            <p>
              危険物、臭気物、生花など
              <br />
              ペットボトル、水筒以外の蓋が無いドリンク類
              <br />
              その他、スタッフが危険であると判断した物品
            </p>
          </article>
          <article>
            <h4 className="ttl-strip">障がい者割引について</h4>
            <p>
              障がい者割引チケットをご購入のお客様は、該当のチケットを購入後、入場口で障がい者手帳をご提示ください。<br />
              ※身体障害者手帳、療育手帳、精神障害者手帳保健福祉手帳の3つが対象です。<br />
              ※交付を受けている方本人のみ割引適用となります。
            </p>
          </article>
        </div>
      </section>

      <ModalContent />
    </Layout>
  );
};

export default Template;
