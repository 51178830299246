/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import { Link } from 'gatsby';
import React from 'react';
import Image from '../util/Image';
import SimpleSwiper from './Slider.fade';

export const List = (props) => (
  <div className="col-inner">
    <div className="col-image">
      <figure className="image" style={{ position: 'relative' }}>
        {props.isNew && (
          <span
            style={{
              display: 'block',
              width: '100px',
              position: 'absolute',
              top: '0px',
              left: '0px',
              zIndex: '9',
            }}
          >
            <Image filename="unko-icon-new.png" />
          </span>
        )}
        {props.imgSlider ? (
          <SimpleSwiper data={props.sliderImg} />
        ) : (
          <Image filename={props.img ? props.img : 'now-printing.jpg'} />
        )}
      </figure>
    </div>
    <div className={`col-content ${props.tac ? 'has-text-align-center' : ''}`}>
      <h3 className="ttl">
        {props.nameimg ? <Image filename={props.nameimg} /> : props.name}
        {props.name}
        {/* <Image filename={props.nameimg} /> */}
      </h3>
      <p className="text">{props.description}</p>
    </div>
  </div>
);
const ContentsListUnko = (props) => (
  <>
    {props.noPointer ? (
      <div className="no-pointer">
        <List {...props} />
      </div>
    ) : (
      <div className="no-link">
        <List {...props} />
      </div>
    )}
  </>
);

export default ContentsListUnko;
