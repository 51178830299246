/**
 * Type: ユーティリティーパーツ
 * What: 表示の出し分けタイマー制御 (フォーマットは2020-12-28 13:44:00)
 */
import moment from 'moment/moment';

const TimerFunc = (start, end, openContent, closeContent) => {
  const time = moment();
  const beforeTime = moment(start, 'YYYY-MM-DD HH:mm:ss');
  const afterTime = moment(end, 'YYYY-MM-DD HH:mm:ss');
  const diff = time.isBetween(beforeTime, afterTime);

  return diff ? openContent : closeContent;
};

export default TimerFunc;
